import { MetricsBase } from './basic_metric';

export class AmplitudeMetrics extends MetricsBase {
  async init() {
    import(/* webpackChunkName: "amplitude" */ '@amplitude/analytics-browser').then((module) => {
      this.amplitude = module;
      this.amplitude.init(AMPLITUDE_KEY);

      this.loaded = true;
    });
  }

  setUser(userId, accountId) {
    this.amplitude.setUserId(userId);

    // set account id as user property
    const identifyEvent = new this.amplitude.Identify();
    identifyEvent.set('Account Id', accountId);
    this.amplitude.identify(identifyEvent);
  }

  async sendEvent(event, data) {
    return await this.amplitude.track(event, data).promise;
  }

  reset() {
    this.amplitude.reset();
  }
}
