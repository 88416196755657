import C from '../eventNames'; // hack to not mess up with translations
import R, { getEvent } from 'radio';
import { AmplitudeMetrics } from './amplitude';
import { DebugMetrics } from './debug';
import moment from 'moment';

const SUPPORTED_STRATEGIES = {
  amplitude: AmplitudeMetrics,
  debug: DebugMetrics,
};

let EVENTS_NOT_SEND = [];

// Class to handle metrics initialization depending on strategy(Google Analytics, Yandex Metrika, etc.)
// and send events to the selected service
export class MetricsController {
  constructor() {
    this.metrics = null;
  }

  _check_unsent_events() {
    const checkInterval = setInterval(() => {
      if (this.metrics.loaded) {
        clearInterval(checkInterval);

        this.sendAllUnsentEvents();
      }
    }, 1000 * 10);
  }

  async init(strategyName) {
    if (!SUPPORTED_STRATEGIES[strategyName]) {
      if (DEVMODE) {
        console.error(`Unsupported metrics strategy: ${strategyName}`);
      }
      return;
    }

    const Strategy = SUPPORTED_STRATEGIES[strategyName];

    this.metrics = new Strategy();

    this.subscribeToEvents();
  }

  subscribeToEvents() {
    this._check_unsent_events();

    // subscribe to events to send metrics
    R.app.on(C.event.authLoggedIn, (auth) => {
      const userId = btoa(auth.get('user').get('email'));

      this.metrics?.setUser(userId, auth.get('user').get('accountGuid'));

      this.sendEvent('Logged In');
    });

    R.app.on(C.event.authSignin, (auth) => {
      const userId = btoa(auth.get('user').get('email'));

      this.metrics?.setUser(userId, auth.get('user').get('accountGuid'));
    });

    R.app.on(C.event.authSignupRequested, (type) => {
      this.sendEvent('Account Created', {
        'Account Type': type,
      });
    });

    R.app.on(C.event.authSignout, async () => {
      await this.sendEvent('Logged Out', {
        'Log Out Type': 'Manual',
      });

      this.metrics.reset();
    });

    R.app.on(C.event.authSignoutAuto, async () => {
      await this.sendEvent('Logged Out', {
        'Log Out Type': 'Auto',
      });

      this.metrics.reset();
    });

    R.app.on(C.command.mapSetBaseLayer, ({ newLayerId }) => {
      this.sendEvent('Base Map Changed', {
        'Base Map Type': newLayerId,
      });
    });

    R.app.on(C.event.accountNewPayment, () => {
      this.sendEvent('Account new payment');
    });

    // Tarriff changing
    R.app.on(C.event.accountTariffChanged, (tarrif) => {
      this.sendEvent(`Plan Changed`, {
        'Plan Name': tarrif,
      });
    });

    R.app.on(C.event.trackerInfoShown, (trackerData) => {
      if (trackerData?.trackTime) {
        const { trackTime } = trackerData;
        const dates = trackTime.split(',').map((dateString) => moment(dateString));
        // because in track we add 1 minute to the end date
        // see file assets/js/common/utilities.js:parseTrackPeriod
        const duration = dates[1].subtract(1, 'minute').diff(dates[0], 'minutes');

        this.sendEvent('Track Viewed', {
          'Track Length': duration,
        });
      }
    });

    // App events
    R.app.once(C.event.appStartupComplete, ({ config }) => {
      R.app.on(C.event.routeNavigate, (route) => {
        this.sendEvent('Page Opened', {
          'Page Name': route,
        });
      });

      for (let type in config) {
        if (config[type]) {
          const name = config[type].collectionTitle;
          const events = getEvent(type);

          // Comment out for now, because we think it will be tracked by page views
          // R.app.on(events.list, () => {
          //   this.sendEvent(`Open list: ${name}`);
          // });
          // R.app.on(events.show, () => {
          //   this.sendEvent(`Open info: ${name}`);
          // });
          // R.app.on(events.update, () => {
          //   this.sendEvent(`Open info: ${name}`);
          // });
          // R.app.on(events.create, () => {
          //   this.sendEvent(`Open info: ${name}`);
          // });

          R.app.on(events.export, () => {
            this.sendEvent(`Report Generated`, {
              'Page Name': name,
            });
          });
          R.app.on(events.exportAsync, () => {
            this.sendEvent(`Report Generated`, {
              'Page Name': name,
            });
          });

          // R.app.on(`${type}:afterCreated`, () => {
          //   this.sendEvent(`Entity create: ${name}`);
          // });
          // R.app.on(`${type}:afterUpdate`, () => {
          //   this.sendEvent(`Entity saved: ${name}`);
          // });
          // R.app.on(`${type}:afterDelete`, () => {
          //   this.sendEvent(`Entity deleted: ${name}`);
          // });
        }
      }
    });
  }

  sendAllUnsentEvents() {
    // send events that were not sent before
    if (EVENTS_NOT_SEND.length > 0) {
      if (DEVMODE) {
        console.log(
          '%cAnalyticsController sending unsent Events',
          'background-color: rebeccapurple;'
        );
        console.log(EVENTS_NOT_SEND.length);
      }

      EVENTS_NOT_SEND.forEach(({ id, data }) => {
        this.sendEvent(id, data);
      });

      EVENTS_NOT_SEND = [];
    }
  }

  async sendEvent(event, data) {
    if (this.metrics.loaded) {
      await this.metrics.sendEvent(event, data);
    } else {
      if (DEVMODE) {
        console.error('Metrics strategy is not initialized');
        console.log(`Sending event ${event} with data: ${data}`);
      }
      EVENTS_NOT_SEND.push({ id: event, data });
    }
  }
}

export function initMetrics() {
  const metricsController = new MetricsController();

  metricsController.init(AMPLITUDE_KEY ? 'amplitude' : 'debug');
}
