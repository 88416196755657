import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';

// Obey alphabetical order in each icon set!

// We load the whole solid icon set cos we can use any solid icon in map layers
// And also we load additional light, regular icons below.

// Light
import { faFileUpload } from '@fortawesome/pro-light-svg-icons/faFileUpload';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faUserCircle } from '@fortawesome/pro-light-svg-icons/faUserCircle';

// Regular
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons/faArrowToBottom';
import { faArrowToLeft } from '@fortawesome/pro-regular-svg-icons/faArrowToLeft';
import { faArrowToRight } from '@fortawesome/pro-regular-svg-icons/faArrowToRight';
import { faArrowToTop } from '@fortawesome/pro-regular-svg-icons/faArrowToTop';
import { faBars } from '@fortawesome/pro-regular-svg-icons/faBars';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faBook } from '@fortawesome/pro-regular-svg-icons/faBook';
import { faBriefcase } from '@fortawesome/pro-regular-svg-icons/faBriefcase';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import { faCalendarTimes } from '@fortawesome/pro-regular-svg-icons/faCalendarTimes';
import { faChartArea } from '@fortawesome/pro-regular-svg-icons/faChartArea';
import { faChartBar } from '@fortawesome/pro-regular-svg-icons/faChartBar';
import { faChartLine } from '@fortawesome/pro-regular-svg-icons/faChartLine';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { faCog } from '@fortawesome/pro-regular-svg-icons/faCog';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import { faDrawCircle } from '@fortawesome/pro-regular-svg-icons/faDrawCircle';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons/faEllipsisV';
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faFile } from '@fortawesome/pro-regular-svg-icons/faFile';
import { faFileChartPie } from '@fortawesome/pro-regular-svg-icons/faFileChartPie';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload';
import { faFilter } from '@fortawesome/pro-regular-svg-icons/faFilter';
import { faFolder } from '@fortawesome/pro-regular-svg-icons/faFolder';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons/faGlobe';
import { faHistory } from '@fortawesome/pro-regular-svg-icons/faHistory';
import { faImages } from '@fortawesome/pro-regular-svg-icons/faImages';
import { faInfo } from '@fortawesome/pro-regular-svg-icons/faInfo';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faLayerMinus } from '@fortawesome/pro-regular-svg-icons/faLayerMinus';
import { faLayerPlus } from '@fortawesome/pro-regular-svg-icons/faLayerPlus';
import { faLink } from '@fortawesome/pro-regular-svg-icons/faLink';
import { faListAlt } from '@fortawesome/pro-regular-svg-icons/faListAlt';
import { faMapMarkedAlt } from '@fortawesome/pro-regular-svg-icons/faMapMarkedAlt';
import { faMapPin } from '@fortawesome/pro-regular-svg-icons/faMapPin';
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile';
import { faPen } from '@fortawesome/pro-regular-svg-icons/faPen';
import { faPhone } from '@fortawesome/pro-regular-svg-icons/faPhone';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons/faPlusCircle';
import { faQrcode } from '@fortawesome/pro-regular-svg-icons/faQrcode';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import { faRoute } from '@fortawesome/pro-regular-svg-icons/faRoute';
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faShareSquare } from '@fortawesome/pro-regular-svg-icons/faShareSquare';
import { faSignalSlash } from '@fortawesome/pro-regular-svg-icons/faSignalSlash';
import { faSignOut } from '@fortawesome/pro-regular-svg-icons/faSignOut';
import { faStream } from '@fortawesome/pro-regular-svg-icons/faStream';
import { faSync } from '@fortawesome/pro-regular-svg-icons/faSync';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons/faSyncAlt';
import { faTable } from '@fortawesome/pro-regular-svg-icons/faTable';
import { faTachometerAlt } from '@fortawesome/pro-regular-svg-icons/faTachometerAlt';
import { faThList } from '@fortawesome/pro-regular-svg-icons/faThList';
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faTrash } from '@fortawesome/pro-regular-svg-icons/faTrash';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
import { faUserAlt } from '@fortawesome/pro-regular-svg-icons/faUserAlt';

// Regular: Vehicle icons
import { faWalking } from '@fortawesome/pro-regular-svg-icons/faWalking';
import { faAmbulance } from '@fortawesome/pro-regular-svg-icons/faAmbulance';
import { faBicycle } from '@fortawesome/pro-regular-svg-icons/faBicycle';
import { faBoxes } from '@fortawesome/pro-regular-svg-icons/faBoxes';
import { faCarSide } from '@fortawesome/pro-regular-svg-icons/faCarSide';
import { faExclamation } from '@fortawesome/pro-regular-svg-icons/faExclamation';
import { faFireExtinguisher } from '@fortawesome/pro-regular-svg-icons/faFireExtinguisher';
import { faForklift } from '@fortawesome/pro-regular-svg-icons/faForklift';
import { faGasPump } from '@fortawesome/pro-regular-svg-icons/faGasPump';
import { faHelicopter } from '@fortawesome/pro-regular-svg-icons/faHelicopter';
import { faMotorcycle } from '@fortawesome/pro-regular-svg-icons/faMotorcycle';
import { faPlane } from '@fortawesome/pro-regular-svg-icons/faPlane';
import { faQuestion } from '@fortawesome/pro-regular-svg-icons/faQuestion';
import { faRocket } from '@fortawesome/pro-regular-svg-icons/faRocket';
import { faRv } from '@fortawesome/pro-regular-svg-icons/faRv';
import { faShieldAlt } from '@fortawesome/pro-regular-svg-icons/faShieldAlt';
import { faShip } from '@fortawesome/pro-regular-svg-icons/faShip';
import { faShuttleVan } from '@fortawesome/pro-regular-svg-icons/faShuttleVan';
import { faSnowmobile } from '@fortawesome/pro-regular-svg-icons/faSnowmobile';
import { faSnowplow } from '@fortawesome/pro-regular-svg-icons/faSnowplow';
import { faStar } from '@fortawesome/pro-regular-svg-icons/faStar';
import { faTaxi } from '@fortawesome/pro-regular-svg-icons/faTaxi';
import { faTools } from '@fortawesome/pro-regular-svg-icons/faTools';
import { faTractor } from '@fortawesome/pro-regular-svg-icons/faTractor';
import { faTruck } from '@fortawesome/pro-regular-svg-icons/faTruck';
import { faTruckContainer } from '@fortawesome/pro-regular-svg-icons/faTruckContainer';
import { faTruckMoving } from '@fortawesome/pro-regular-svg-icons/faTruckMoving';
import { faTruckPickup } from '@fortawesome/pro-regular-svg-icons/faTruckPickup';
import { faWrench } from '@fortawesome/pro-regular-svg-icons/faWrench';
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faGarageCar } from '@fortawesome/pro-solid-svg-icons/faGarageCar';

library.add(
  fas,
  faAmbulance,
  faArrowLeft,
  faArrowRight,
  faArrowToBottom,
  faArrowToLeft,
  faArrowToRight,
  faArrowToTop,
  faBars,
  faBell,
  faBicycle,
  faBook,
  faBriefcase,
  faCalendarAlt,
  faCalendarTimes,
  faCarSide,
  faChartArea,
  faChartBar,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCog,
  faCopy,
  faEllipsisV,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faFile,
  faFileChartPie,
  faFileDownload,
  faFileUpload,
  faFilter,
  faFireExtinguisher,
  faFolder,
  faForklift,
  faGasPump,
  faGlobe,
  faHelicopter,
  faHistory,
  faImages,
  faInfo,
  faInfoCircle,
  faLink,
  faListAlt,
  faMapMarkedAlt,
  faMapPin,
  faMobile,
  faMotorcycle,
  faPen,
  faPhone,
  faPlane,
  faPlus,
  faPlusCircle,
  faQrcode,
  faQuestion,
  faQuestionCircle,
  faRocket,
  faRoute,
  faRv,
  faSave,
  faSearch,
  faShareSquare,
  faShieldAlt,
  faShip,
  faShuttleVan,
  faSignOut,
  faSignalSlash,
  faSnowmobile,
  faSnowplow,
  faSquare,
  faStar,
  faStream,
  faSync,
  faSyncAlt,
  faTable,
  faTachometerAlt,
  faTaxi,
  faThList,
  faTimes,
  faTimesCircle,
  faTools,
  faTractor,
  faTrash,
  faTruck,
  faTruck,
  faTruckContainer,
  faTruckMoving,
  faTruckPickup,
  faUser,
  faUserAlt,
  faUserCircle,
  faWrench,
  faLayerPlus,
  faLayerMinus,
  faDrawCircle,
  faGarageCar,
  faCircle,
  faBoxes,
  faAddressCard,
  faWalking
);

dom.watch();
