import Radio from 'backbone.radio';

const entity = Radio.channel('entity');
const app = Radio.channel('app');

export function getEvent(type) {
  return {
    list: `${type}:list`,
    create: `${type}:create`,
    show: `${type}:show`,
    update: `${type}:update`,
    hide: `${type}:hide`,
    export: `${type}:export`,
    exportAsync: `${type}:exportAsync`,
    import: `${type}:import`,
  };
}

export default {
  entity,
  app,
};
