import { initi18 } from 'i18n/i18n';
import { initi18Events } from 'i18n/i18events';
import { initMetrics } from 'metrics/controller';
import 'sentry';
import * as CookieConsent from 'vanilla-cookieconsent';
import { i18 } from 'i18n/i18n';

// import important modules like main css and bootstrap
import 'bootstrap'; // importing bootstrap
import 'common/icons'; // loading font awesome icons
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import '../css/main.scss';

if (DEVMODE) {
  // eslint-disable-next-line global-require
  require('preact/debug');
}

function initCookieConsent() {
  const language = i18.resolvedLanguage ?? 'en';

  const translations = {
    [language]: {
      ...i18.getResourceBundle(i18.resolvedLanguage, 'cookieConsent'),
    },
  };

  CookieConsent.run({
    guiOptions: {
      consentModal: {
        layout: 'box',
        position: 'bottom left',
      },
      preferencesModal: {
        layout: 'box',
        position: 'right',
        flipButtons: true,
      },
    },
    categories: {
      necessary: {
        readOnly: true,
      },
    },
    disablePageInteraction: true,
    language: {
      default: language,
      translations,
    },
  });
}

async function init() {
  await initi18();
  initi18Events();

  initMetrics();

  const { initAuth } = await import('./auth');

  await initAuth();

  initCookieConsent();
}

init();
