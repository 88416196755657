// abstract class for metrics strategy
export class MetricsBase {
  loaded = false;

  constructor(options) {
    this.init(options);
  }

  // init method should load async metrics script and set loaded true on finish
  async init() {
    throw new Error('init method is not implemented');
  }

  setUser(userId, accountId) {
    throw new Error('setUser method is not implemented');
  }

  async sendEvent(event, data) {
    throw new Error('sendEvent method is not implemented');
  }

  // reset metrics on user logout
  reset() {}
}
