const { MetricsBase } = require('./basic_metric');

export class DebugMetrics extends MetricsBase {
  init() {
    console.log('Debug metrics initialized');
    this.loaded = true;
  }

  setUser(userId, accountId) {
    console.log(`Setting user ${userId} - ${accountId} to debug metrics`);
  }

  sendEvent(eventName, ...args) {
    console.log('%cSending event to analitics ' + eventName, 'background-color: red;');
    console.log(args);
  }

  reset() {
    console.log('%cResetting debug metrics', 'background-color: red;');
  }
}
